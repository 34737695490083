<template>
  <div class="modal">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <h4 class="pb-5">Raise invoice</h4>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-1">
        <label>SubTotal</label>
      </div>
      <div class="cell small-12 medium-1">
        <label>VAT</label>
      </div>
      <div class="cell small-12 medium-2">
        <label>Total</label>
      </div>
      <div class="cell small-12 medium-2">
        <label>Already invoiced</label>
      </div>
      <div class="cell small-12 medium-2">
        <label>Balance</label>
      </div>
      <div class="cell small-12 medium-2">
        <label>% Of balance to invoice</label>
      </div>
      <div class="cell small-12 medium-2">
        <label>Amount</label>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-1">
        {{ estimate.subTotal | currency }}
      </div>
      <div class="cell small-12 medium-1">
        {{ estimate.vat | currency }}
      </div>
      <div class="cell small-12 medium-2">
        {{ estimate.totalValue | currency }}
      </div>
      <div class="cell small-12 medium-2">
        {{ alreadyInvoiced | currency }}
      </div>
      <div class="cell small-12 medium-2">
        {{ balance | currency }}
      </div>
      <div class="cell small-12 medium-2">
        <input type="number" step="0.01" v-model="invoicePercentage">
      </div>
      <div class="cell small-12 medium-2">
        {{ invoiceAmount | currency }}
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2">
        <button @click="raiseInvoice" class="button expanded">Raise Invoice</button>
      </div>
      <div class="cell small-12 medium-2">
        <button @click="cancel" class="button red expanded">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'EstimatesRaiseInvoice',
  props: [
    'estimate',
  ],
  computed: {
    alreadyInvoiced() {
      let amount = 0;
      for (let i = 0; i < this.estimate.invoices.length; i += 1) {
        amount += parseFloat(this.estimate.invoices[i].totalValue);
      }
      return amount;
    },
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      invoiceAmount: 0,
      invoicePercentage: 100,
      balance: 0,
    };
  },
  methods: {
    raiseInvoice() {
      const postData = {};
      postData.estimate = this.estimate;
      postData.invoiceAmount = this.invoiceAmount;
      postData.userid = this.userid;
      axios.post(`/invoices/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('invoiceRaised', response.data);
        });
    },
    setInvoiceAmount() {
      if (this.alreadyInvoiced === 0) {
        this.invoicePercentage = this.estimate.deposit;
        this.balance = this.estimate.totalValue;
        this.invoiceAmount = this.estimate.totalValue * (this.estimate.deposit / 100);
      } else {
        this.invoiceAmount = (this.estimate.totalValue - this.alreadyInvoiced);
        this.balance = (this.estimate.totalValue - this.alreadyInvoiced);
        this.invoicePercentage = (this.balance / this.estimage.totalValue) * 100;
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  mounted() {
    this.setInvoiceAmount();
  },
};
</script>
