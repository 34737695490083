<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <h4 class="pb-2">Convert estimate</h4>
      </div>
    </div>
    <div v-if="converted === false" class="grid-x grid-padding-x">
      <div class="cell small-12">
        <p>
          Do you want to convert this to a Live Job now?
          <br />
          <button
            @click="convertJob()"
            class="button green small">Yes</button>
          &nbsp;
          <button
            @click="$emit('hideConvert')"
            class="button red small">Cancel</button>
        </p>
      </div>
    </div>
    <div v-if="converted === true && showAddPo === true" class="grid-x grid-padding-x">
      <div class="cell small-12">
        <AddPo
          :clientId="estimate.client_id"
          :estimate="estimate"
          @cancelPo="$emit('hideConvert')"
          @poSaved="poAdded" />
      </div>
    </div>
    <div v-if="poAdded === true" class="grid-x grid-padding-x pt-4">
      <div class="cell small-12">
        <p>
          The deposit required for this order is {{ estimate.deposit }}%
          <br />
          Do you want to raise an invoice for this now?
          <br />
          <button @click="raiseAnInvoice = true" class="button small">Raise Invoice</button>
        </p>
      </div>
    </div>
    <RaiseInvoice
      v-if="raiseAnInvoice"
      @cancel="raiseAnInvoice = false"
      @invoiceRaised="$emit('hideConvert')"
      :estimate="estimate" />
  </div>
</template>

<script>
import RaiseInvoice from './RaiseInvoice.vue';
import AddPo from './AddPo.vue';
import axios from '../../../axios';

export default {
  name: 'EstimatesConvert',
  components: {
    RaiseInvoice,
    AddPo,
  },
  props: [
    'estimate',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      converted: false,
      showAddPo: true,
      raiseAnInvoice: false,
    };
  },
  methods: {
    convertJob() {
      axios.get(`/estimates/convert/${this.estimate.id}/${this.userid}.json?token=${this.token}`)
        .then(() => {
          this.converted = true;
          this.$emit('estimateConverted');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    poAdded(value) {
      this.showAddPo = false;
      this.estimate.hasPo = true;
      this.$emit('poSaved', value);
    },
  },
};
</script>
