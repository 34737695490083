<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1>View Estimate</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <button
          v-if="estimate.converted === false"
          @click="showConvert = true"
          class="button small amber">Draft</button>&nbsp;
        <button
          v-if="estimate.converted === true"
          class="button small green">Live</button>&nbsp;
        <button
          @click="showNotes = true"
          class="button small">Notes</button>&nbsp;
        <router-link :to="`/estimates/preview/${estimate.id}`">
          <button
            class="button small">Client view</button>&nbsp;
        </router-link>
        <button
          @click="showUploads = true"
          class="button small">Upload file</button>&nbsp;
        <span v-if="estimate.po_number === 0 && estimate.converted === true">
          <button
            @click="showAddPo = true"
            class="button small">Add Po</button>&nbsp;
        </span>
        <span v-if="estimate.po_number.length > 0">
          <button
            @click="showRaiseInvoice = true"
            class="button small">Add Invoice</button>&nbsp;
        </span>
        <button
          @click="editDetails = true"
          class="button small">Edit details</button>&nbsp;
        <router-link :to="`/estimates/add-lines/${estimate.id}`">
          <button class="button small">Add / Edit Lines</button>
        </router-link>
        &nbsp;
        <router-link
            :to="`/estimates/revise/${estimate.id}`"
            v-if="estimate.converted === false">
          <button class="button small">Revise Estimate</button>
        </router-link>
        <span
          @click="showRaiseInvoice = true"
          v-if="estimate.converted === true">
          <button class="button small">Raise Invoice</button>
        </span>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6">
        <h4>Details</h4>
        <p>
          Client: {{ estimate.client.name }}<br />
          Brand: {{ estimate.brand.name }}<br />
          Job Number: {{ estimate.job_number.job_number }}<br />
          Job Description: {{ estimate.job_number.description }}<br />
          Created by: {{ estimate.user.first_name }} {{ estimate.user.last_name }}
          <span v-if="estimate.po_number.length > 0">
            <br />PO Number: {{ estimate.po_number }}
          </span>
          <span v-if="estimate.po_file.id">
            <br />View PO:
            <!-- eslint-disable max-len -->
            <a
              :href="`${baseUrl}/original/${estimate.po_file.assembly}__${estimate.po_file.name}.${estimate.po_file.ext}`"
              target="_blank">{{ estimate.po_file.name }}</a>
            <!-- eslint-enable max-len -->
          </span>
        </p>
      </div>
      <div class="cell small-12 medium-6">
        <h4>Notes</h4>
        <div
          v-for="(note, index) in estimate.estimate_notes"
          :key="`en_${index}`"
          v-html="note.note">
        </div>
      </div>
    </div>
    <div v-if="estimate.invoices.length > 0" class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6">
        <h4>Invoices</h4>
        <table>
          <thead>
            <tr>
              <th>Invoice number</th>
              <th>Date</th>
              <th>Amount</th>
              <th>% of Total</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invoice, index) in estimate.invoices" :key="`i_${index}`">
              <td>{{ invoice.id }}</td>
              <td>{{ invoice.created | tinyDate }}</td>
              <td>{{ invoice.totalValue | currency }}</td>
              <td>{{ invoice.percentageOfTotal }}%</td>
              <td>
                <router-link :to="`/invoices/view/${invoice.id}`">
                  <button class="button small">View</button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-4">
        <div class="cell small-10">
          <h4>Estimate includes</h4>
          <p>{{ estimate.estimate_includes }}</p>
        </div>
      </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <h4 class="pb-2">Fee Estimate</h4>
        <div v-if="estimate.show_breakdown">
          <table v-for="(group, index) in estimate.internal" :key="`g_${index}`">
            <thead>
              <tr>
                <th style="width:60%">{{ group.name }}</th>
                <th style="width:20%">Hours</th>
                <th class="text-right" style="width:20%">Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                <td>
                  <span v-if="line.external === true">{{ line.description }}</span>
                  <span v-else>{{ line.personnel }}</span>
                </td>
                <td>
                  <span v-if="line.external === false">{{ line.hours }}:{{ line.minutes }}</span>
                </td>
                <td class="text-right">
                  <span v-if="line.external === true">{{ line.cost_each | currency }}</span>
                  <span v-else>{{ line.lineValue | currency }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <table v-for="(group, index) in estimate.external" :key="`ge_${index}`">
            <thead>
              <tr>
                <th style="width:80%">{{ group.name }}</th>
                <th class="text-right" style="width:20%">Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                <td>{{ line.description }}</td>
                <td class="text-right">
                  {{ (line.cost_each * line.quantity) | currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <table v-for="(group, index) in estimate.internal" :key="`g_${index}`">
            <tbody>
              <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                <td style="width:60%">{{ group.name }}</td>
                <td style="width: 20%">{{ line.hours }}:{{ line.minutes }}</td>
                <td style="width:20%" class="text-right">{{ (line.cost) | currency }}</td>
              </tr>
            </tbody>
          </table>
          <table v-for="(group, index) in estimate.external" :key="`ge_${index}`">
            <tbody>
              <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                <td style="width:60%">{{ group.name }}</td>
                <td style="width:20%">{{ line.description }}</td>
                <td style="width:20%" class="text-right">
                  {{ (line.cost_each * line.quantity) | currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      v-if="estimate.external.lines"
      class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <h4 class="pb-2">Expenses Estimate</h4>
        <table v-for="(group, index) in estimate.external" :key="`g_${index}`">
          <thead>
            <tr>
              <th style="width:60%">{{ group.name }}</th>
              <th style="width:20%">Hours</th>
              <th class="text-right" style="width:20%">Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
              <td>{{ line.description }}</td>
              <td>{{ line.quantity }}</td>
              <td class="text-right">{{ (line.cost_each * line.quantity) | currency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <table>
          <thead>
            <tr>
              <th>Total</th>
              <th class="text-right">{{ estimate.subTotal | currency }}</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div v-if="estimate.files.length > 0" class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <h4 class="pb-2">Files</h4>
        <table>
          <tbody>
            <tr v-for="(file, index) in estimate.files" :key="`f_${index}`">
              <td>
                <a
                  :href="`${baseUrl}/original/${file.assembly}__${file.name}.${file.ext}`"
                  target="_blank">{{ file.name }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <AddPo
      v-if="showAddPo === true"
      @cancelPo="showAddPo = false"
      @poSaved="addPoNumber"
      :estimate="estimate" />
    <Convert
      v-if="showConvert"
      @hideConvert="showConvert = false"
      @estimateConverted="estimateConverted"
      @poSaved="addPoNumber"
      :estimate="estimate" />
    <EditTheDetails
      v-if="editDetails === true"
      @closeDialogue="editDetails = false"
      @saveDetails="saveDetails"
      :estimateId="estimate.id"/>
    <Notes
      v-if="showNotes"
      @hideNotes="showNotes = false"
      @addNote="addNote"
      @updateNote="updateNote"
      @deleteNote="deleteNote"
      :notes="estimate.estimate_notes"
      :estimateId="estimate.id" />
    <RaiseInvoice
      v-if="showRaiseInvoice"
      @cancel="showRaiseInvoice = false"
      @invoiceRaised="invoiceIsRaised"
      :estimateId="estimate.id" />
    <Uploads
      v-if="showUploads"
      @hideUploads="showUploads = false"
      @completeUploads="completeUploads"
      :modelId="estimate.id"
      :model="'Estimates'"
      :type="'EST'"
      :clientId="estimate.client.id" />
    <Message
      v-if="showMessage === true"
      :messageText="message"
      :link="messageLink"
      :linkText="messageLinkText"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import AddPo from '@/views/estimates/components/AddPo.vue';
import Convert from '@/views/estimates/components/Convert.vue';
import EditTheDetails from '@/views/estimates/components/EditDetails.vue';
import Message from '@/components/messages/Message.vue';
import Notes from '@/views/estimates/components/Notes.vue';
import RaiseInvoice from '@/views/job-numbers/components/RaiseInvoice.vue';
import Uploads from '@/components/UploadFile.vue';
import axios from '../../axios';

export default {
  name: 'EstimateView',
  components: {
    AddPo,
    Convert,
    EditTheDetails,
    Message,
    Notes,
    RaiseInvoice,
    Uploads,
  },
  data() {
    return {
      baseUrl: 'https://dice-dash.s3.eu-west-2.amazonaws.com',
      editDetails: false,
      estimate: {
        client: {},
        brand: {},
        job_number: {},
        po_number: 0,
        user: {},
        estimate_lines: [],
        external: {},
        files: [],
        invoices: [],
        po_file: {},
      },
      message: '',
      messageLink: false,
      messageLinkText: false,
      showAddPo: false,
      showConvert: false,
      showMessage: false,
      showNotes: false,
      showRaiseInvoice: false,
      showUploads: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    addPoNumber(value) {
      console.log(value);
      this.showAddPo = false;
      this.showConvert = false;
      this.estimate.po_number = value.po_number;
      if (value.file) {
        this.estimate.po_file = value.file;
      }
    },
    estimateConverted() {
      this.estimate.converted = true;
    },
    completeUploads(response) {
      let message = '';
      for (let i = 0; i < response.length; i += 1) {
        this.estimate.files.push(response[i].file);
        message = `${i + 1} files saved.`;
      }
      this.showUploads = false;
      this.showMessage = true;
      this.message = message;
    },
    invoiceIsRaised(value) {
      this.showRaiseInvoice = false;
      this.estimate.invoices.push(value.invoice);
    },
    addNote(response) {
      this.estimate.estimate_notes.push(response.note);
      this.showNotes = false;
      this.showMessage = true;
      this.message = response.message;
    },
    updateNote(result) {
      this.estimate.estimate_notes.splice(result.index, 1, result.response.note);
      this.showNotes = false;
      this.showMessage = true;
      this.message = result.response.message;
    },
    deleteNote(response) {
      this.estimate.estimate_notes.splice(response, 1);
    },
    saveDetails(value) {
      this.editDetails = false;
      this.estimate.estimate_includes = value;
    },
    getEstimate() {
      const estimateId = this.$route.params.id;
      axios.get(`/estimates/getOne/${estimateId}.json?token=${this.token}`)
        .then((response) => {
          this.estimate = response.data.estimate;
          this.getRates();
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getEstimate();
    }, 100);
  },
};
</script>
