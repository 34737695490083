<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table v-if="!addNote && noteInEdit === -1">
          <thead>
            <tr>
              <th>Note</th>
              <th>Added</th>
              <th>By</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(note, index) in notes" :key="`n_${index}`">
              <td>{{ note.note }}</td>
              <td>{{ note.created | tinyDate }}</td>
              <td>{{ note.created_by_user.first_name }} {{ note.created_by_user.last_name }}</td>
              <td class="text-right">
                <span
                  title="Edit note"
                  class="material-symbols-outlined icon-yellow icon-link"
                  @click="noteInEdit = index, selectedNote = note"
                  >edit</span>
                <span
                  title="Delete note"
                  class="material-symbols-outlined icon-red icon-link"
                  @click="deleteNote(index)"
                  >delete</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="addNote" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <label>Note</label>
        <textarea v-model="newNote.note"></textarea>
      </div>
    </div>
    <div v-if="noteInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <label>Note</label>
        <textarea v-model="selectedNote.note"></textarea>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10 text-right">
        <button
          v-if="!addNote && noteInEdit === -1"
          @click="addNote = true"
          class="button small">Add</button>
        &nbsp;
        <button
          v-if="addNote"
          @click="saveNote"
          class="button small">Save</button>
        &nbsp;
        <button
          v-if="noteInEdit > -1"
          @click="updateNote"
          class="button small">Save changes</button>
        &nbsp;
        <button @click="$emit('hideNotes')" class="button small">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'EstimatesNotes',
  props: [
    'notes',
    'estimateId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      addNote: false,
      newNote: {
        note: '',
      },
      noteInEdit: -1,
      selectedNote: {},
    };
  },
  methods: {
    saveNote() {
      const postData = {};
      postData.note = this.newNote;
      postData.note.estimate_id = this.estimateId;
      postData.note.created_by = this.userid;
      axios.post(`/estimateNotes/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('addNote', response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateNote() {
      const postData = {};
      postData.note = this.selectedNote;
      const noteId = this.selectedNote.id;
      axios.post(`/estimateNotes/update/${noteId}.json?token=${this.token}`, postData)
        .then((response) => {
          const result = {};
          result.response = response.data;
          result.index = this.noteInEdit;
          this.$emit('updateNote', result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteNote(index) {
      const noteId = this.notes[index].id;
      axios.get(`/estimateNotes/delete/${noteId}.json?token=${this.token}`)
        .then(() => {
          this.$emit('deleteNote', index);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
