<template>
  <div class="preview-box">
    <span
      class="no-print print-preview material-symbols-outlined icon-yellow icon-link"
      @click="print()"
    >print</span>
    <v-icon
    class="no-print close-preview material-symbols-outlined icon-yellow icon-link"
      @click="goBack()"
    >cancel</v-icon>
    <div class="preview-inner">
      <div class="grid-x grid-padding-x">
        <div class="cell small-10">
          <h1>Cost Estimate</h1>
        </div>
        <div class="cell small-2">
          <img width="180px" src="../../assets/logo.png" alt="" />
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-2">
        <div class="cell small-8">
          <table class="dense">
            <tbody>
              <tr>
                <td>Client:</td>
                <td>{{ estimate.client.name }}</td>
              </tr>
              <tr>
                <td>Job description:</td>
                <td>{{ estimate.job_number.description }}</td>
              </tr>
              <tr>
                <td>Dice code:</td>
                <td>{{ estimate.job_number.job_number }}</td>
              </tr>
              <tr>
                <td>Date:</td>
                <td>{{ estimate.created | tinyDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-4">
        <div class="cell small-10">
          <h4>Estimate includes</h4>
          <p>{{ estimate.estimate_includes }}</p>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-10">
          <h4 class="pb-2">Fee Estimate</h4>
          <div v-if="estimate.show_breakdown">
            <table v-for="(group, index) in estimate.internal" :key="`g_${index}`">
              <thead>
                <tr>
                  <th style="width:60%">{{ group.name }}</th>
                  <th style="width:20%">Hours</th>
                  <th class="text-right" style="width:20%">Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                  <td>
                    <span v-if="line.external === true">{{ line.description }}</span>
                    <span v-else>{{ line.personnel }}</span>
                  </td>
                  <td>
                    <span v-if="line.external === false">{{ line.hours }}:{{ line.minutes }}</span>
                  </td>
                  <td class="text-right">
                    <span v-if="line.external === true">{{ line.cost_each | currency }}</span>
                    <span v-else>{{ line.lineValue | currency }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table v-for="(group, index) in estimate.external" :key="`ge_${index}`">
              <thead>
                <tr>
                  <th style="width:80%">{{ group.name }}</th>
                  <th class="text-right" style="width:20%">Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                  <td>{{ line.description }}</td>
                  <td class="text-right">{{ (line.cost_each * line.quantity) | currency }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <table>
              <thead>
                <tr>
                  <th style="width:80%">&nbsp;</th>
                  <th class="text-right" style="width:20%">Cost</th>
                </tr>
              </thead>
            </table>
            <table v-for="(group, index) in estimate.internal" :key="`g_${index}`">
              <tbody>
                <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                  <td style="width:80%">{{ group.name }}</td>
                  <td style="width:20%" class="text-right">{{ (line.cost) | currency }}</td>
                </tr>
              </tbody>
            </table>
            <table v-for="(group, index) in estimate.external" :key="`ge_${index}`">
              <thead>
                <tr>
                  <th style="width:80%">{{ group.name }}</th>
                  <th colspan="2" class="text-right" style="width:20%">Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                  <td>{{ line.description }}</td>
                  <td>{{ line.quantity }}</td>
                  <td class="text-right">{{ (line.cost_each * line.quantity) | currency }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        v-if="estimate.external.lines"
        class="grid-x grid-padding-x pt-5">
        <div class="cell small-10">
          <h4 class="pb-2">Expenses Estimate</h4>
          <table class="dense" v-for="(group, index) in estimate.external" :key="`g_${index}`">
            <thead>
              <tr>
                <th style="width:60%">{{ group.name }}</th>
                <th style="width:20%">Hours</th>
                <th class="text-right" style="width:20%">Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                <td>{{ line.description }}</td>
                <td>{{ line.quantity }}</td>
                <td class="text-right">{{ (line.cost_each * line.quantity) | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-10">
          <table class="dense">
            <thead>
              <tr>
                <th>Total cost:</th>
                <th class="text-right">{{ estimate.subTotal | currency }}</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div class="grid-x grid-padding-x mt-3">
        <div class="cell small-10">
          <p class="text-small">
            Estimates are valid for 60 days, except for the price of materials where prices at the
            time of ordering will apply. All estimates are subject to confirmation once the project
            is approved in principle, or in the case of production estimates, once the project is
            approved to progress to production. VAT where applicable will be charged as an extra.
          </p>
        </div>
      </div>
      <div class="grid-x grid-padding-x mt-3">
        <div class="cell small-10">
          <p class="text-small">
            The Yard, 14-18 Bell Street, Maidenhead, Berkshire, SL6 1BR
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'EstimatePreview',
  computed: {
    token() {
      return this.$store.state.token;
    },
    estimateTotal() {
      let total = 0;
      for (let i = 0; i < this.estimate.estimate_lines.length; i += 1) {
        total += (this.estimate.estimate_lines[i].quantity
          * this.estimate.estimate_lines[i].cost_each);
      }
      return total;
    },
  },
  data() {
    return {
      estimate: {},
    };
  },
  methods: {
    print() {
      window.print();
    },
    goBack() {
      this.$router.go('-1');
    },
    getEstimate() {
      const estimateId = this.$route.params.id;
      axios.get(`/estimates/getOne/${estimateId}.json?token=${this.token}`)
        .then((response) => {
          this.estimate = response.data.estimate;
          if (!response.data.estimate.estimate_lines) this.estimate.estimate_lines = [];
        });
    },
  },
  mounted() {
    this.getEstimate();
  },
};
</script>

<style lang="scss" scoped>
@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  .preview-inner {
    margin: 10mm 5mm 10mm 5mm;
  }
}
</style>
