<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <h4>Edit details</h4>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <label>Estimate includes</label>
        <textarea v-model="estimate.estimate_includes"></textarea>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <button @click="save" class="button">Save</button>
        &nbsp;
        <button @click="$emit('closeDialogue')" class="button red">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'EditDetails',
  props: [
    'estimateId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      estimate: {},
    };
  },
  methods: {
    save() {
      const postData = this.estimate;
      axios.post(`/estimates/update.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('saveDetails', response.data.estimate_includes);
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getEstimate() {
      axios.get(`/estimates/getOne/${this.estimateId}.json?token=${this.token}`)
        .then((response) => {
          this.estimate = response.data.estimate;
          this.getRates();
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    this.getEstimate();
  },
};
</script>
