<template>
  <div class="dialogue wide">
    <h4 class="pb-5">Add Purchase Order</h4>
    <label>Purchase order number</label>
    <input type="text" v-model="po_number">
    <div class="uploads"></div>
    <div class="text-right pt-5">
      <button @click="savePo" class="button small green">Save</button>
      &nbsp;
      <button @click="$emit('cancelPo')" class="button small red">Cancel</button>
    </div>
  </div>
</template>

<script>
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Transloadit from '@uppy/transloadit';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import axios from '../../../axios';

export default {
  name: 'UploadFile',
  props: [
    'estimate',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      po_number: '',
      newFile: {},
    };
  },
  methods: {
    savePo() {
      const postData = {};
      postData.estimateId = this.estimate.id;
      postData.po_number = this.po_number;
      axios.post(`/estimates/savePo.json?token=${this.token}`, postData)
        .then(() => {
          const data = {};
          data.po_number = this.po_number;
          if (this.newFile[0]) {
            data.file = this.newFile[0].file;
          }
          this.$emit('poSaved', data);
        });
    },
  },
  mounted() {
    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 10,
        minNumberOfFiles: 1,
      },
    });
    uppy.use(Dashboard, {
      inline: true,
      width: '100%',
      target: '.uploads',
      proudlyDisplayPoweredByUppy: false,
    })
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
          auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
          template_id: '1896d036d8874cbe85fa470c0672fe80',
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
        fields: {},
        limit: 0,
      })
      .on('complete', (results) => {
        const postData = {};
        postData.results = results;
        postData.model = 'Estimates';
        postData.modelId = this.estimate.id;
        postData.clientId = this.estimate.client_id;
        postData.type = 'PO';
        axios.post(`/files/create.json?token=${this.token}`, postData)
          .then((response) => {
            // eslint-disable-next-line
            this.newFile = response.data;
            this.savePo();
          });
      });
  },
};
</script>

<style lang="scss">
.uppy-Dashboard-inner {
  max-height: 350px!important;
}
</style>
